.support-inquiry{
  justify-content: space-between;margin-bottom:100px;
  .top-text{margin-bottom:40px;display:flex;flex-direction:column;row-gap:16px;
    .title{font-size: 40px;font-weight: 700;line-height: 130%;}
    .text{font-size: 18px;font-weight: 400;}
  }
  .email-text{
    display:flex;flex-direction:column;row-gap:16px;margin-bottom:216px;
    .title{font-size: 32px;font-weight: 700;}
    p{color:$gray-6;}
  }
  .form-container{
    .btn{margin-top:82px;}
  }
  .terms{
    .terms-title{margin-bottom:8px;font-weight:700;}
    .terms-content{
      padding:12px;margin-bottom:16px;background-color:$bg-color;font-size:14px;line-height:22.4px;color:#999;
      .scroll-bar{height:196px;}
    }
  }
  @include media-breakpoint-down(md){
    margin-bottom:0;
    .top-text{
      .title{font-size: 24px;}
      .text{font-size: 15px;}
    }
    .email-text{
      row-gap:8px;margin-bottom:40px;
      .title{font-size: 24px;}
      p{font-size:14px;}
    }
    .form-container{
      margin-top:80px;
      .btn{margin-top:40px;}
    }
    .terms{
      .terms-content{
        .scroll-bar{height:266px;}
      }
    }
  }
}



.support-banner{
  display:flex;column-gap:109px;padding:44px 0 39px 58px;margin-top:80px;border-radius: 24px;background: rgba(9, 101, 238, 0.04) url("../images/bg-support-banner.png")no-repeat 100% 100%;
  .text-box{
    color: #202434;
    .title{margin-bottom:14px;font-size: 32px;font-weight: 700;line-height: 160%;}
    .btn{
      width:200px;margin-top:20px; background-color: #445FE3 !important; margin-top: 33px;
    }
  }
  @include media-breakpoint-down(md){
    display:none;
  }
}

//qna-list
.icon-write{width:19px;height:18px;margin-right:12px;background:url("../images/icon-write.svg");}
.qna-list{
  margin-bottom:40px;
  ul{
    li{
      border-bottom:1px solid #EEEEEE;
      a{
        display:flex;align-items:center;justify-content:space-between;padding:24px 0;
        .title-line{
          display:flex;align-items:center;column-gap:16px;width: calc(100% - 210px);
          .badge-noti{display:inline-block;padding:5px 10px;background-color: $primary;border-radius:4px;font-size: 14px;font-weight: 700;color:#fff;letter-spacing: -0.07px;}
          .num{display:inline-block;min-width:45px;text-align:center;color:#999;}
          p{width: calc(100% - 70px);font-size:18px;text-overflow: ellipsis;overflow: hidden;text-wrap: nowrap;}
        }
        .info{
          display:flex;align-items:center;column-gap:16px;color:$gray-6;
          .date{color:#999;}
        }
      }
    }
  }
  @include media-breakpoint-down(md){
    ul{
      li{
        a{
          flex-direction:column;row-gap:16px;align-items:start;padding:16px 0;
          .title-line{
            flex-direction:column;align-items:start;width:100%;row-gap: 16px;
            .badge-noti{padding:4px 10px;font-size: 12px;}
            .num{display:inline-block;min-width:auto;}
            p{width:100%;font-size:15px;display: -webkit-box;-webkit-box-orient: vertical;white-space: inherit;-webkit-line-clamp: 2;word-break: break-all;}
          }
          .info{
            width: 100%;justify-content:space-between;
          }
        }
      }
    }
    
  }
}

//qna-write
.qna-write-wrap{
  .top-text{row-gap:40px;margin-bottom:186px;}
}
.scroll-textarea{height: 340px;
  padding: 8px;
  background-color: $bg-color;
  textarea{
    overflow-y:auto;padding:0 8px 0 0;
    &::-webkit-scrollbar{width:4px;background-color: $border-color;}
    &::-webkit-scrollbar-thumb {background-color:#999999}
  }
}


//qna-detail
.icon-reply{width:22px;height:24px;background:url("../images/icon-reply.svg");}
.comment-write{
  width: 100%; margin-top: 80px; display: flex; flex-direction: column; align-items: flex-end;
  button{margin-top:40px;}
  textarea {padding: 24px 35px;border-radius:0;}
  @include media-breakpoint-down(md){
    margin-top: 30px;
    textarea {
      padding: 15px 20px; border-radius: 0;
    }
  }
}
.comment-wrap{
  border-top: 2px solid #231815; margin-top: 80px;
  .reply{
    display: flex; padding: 34px 30px; border-bottom: 1px solid $border-color;
    .desc{
      width: 80%; line-height: 28px; color: #212222; display: flex; align-items: flex-start;
      .icon-lock{ margin-right: 8px;}
    }
    .info{
      display: flex;align-items: center;width:20%;
      .user{
        width:50%; font-weight: 500; font-size: 16px; line-height: 28px; color: $gray-6; text-align: center; word-break: break-all;
      }
      .date{
        width:50%; display: flex; justify-content: end; font-size: 16px; line-height: 28px; color: #5A5B5D;
      }
    }

    &.re-reply{
      display: flex;align-items: start;padding: 36px 30px; border-bottom: 1px solid $border-color; background-color:$bg-color;
      .icon-reply{margin-right:15px;}
      .info{
        .answer{width:auto;margin:0 auto;padding:8px 12.75px;border-radius:32px;border:1px solid $primary;color:$primary;font-size:14px;font-weight:500;line-height:16.8px;}
      }
      
    }
  }
  .no-reply{
    padding: 87px 0; display: flex; align-items: center; justify-content: center;border-bottom: 1px solid $border-color;
    .desc{font-size: 16px; line-height: 19px; color:$gray-6; }
  }
  @include media-breakpoint-down(md){
    .reply{
      flex-direction:column;row-gap: 5px;align-items: flex-start; padding: 18px 12px 18px 15px; border-bottom: 1px solid #ddd;
      .desc{
        width: 100%; font-size: 14px; line-height: 24px;
        .icon-lock{ margin-right: 8px;}
      }
      .info{
        width:100%;
        .user{width: auto;font-weight: 500; font-size: 14px; line-height: 24px; display: inline-block; margin-right: 16px; }
        .date{width: auto;font-size: 14px; line-height: 24px;}
      }
      &.re-reply{
        padding: 18px 12px 18px 15px;
        .info{
          .answer{margin:0 16px 0 0;padding: 5px 11.75px;border-radius: 28px;line-height: 13px;font-size: 13px;}
        }
      }
    }
  
    .no-reply{
      padding: 80px 0; display: flex; align-items: center; justify-content: center;
      .desc{ font-weight: 500; font-size: 16px; line-height: 19px;}
    }
  }
}

  


//faq
.top-scroll-tab-box{
  display:flex;justify-content: space-between;align-items: center;margin-bottom:40px;
  .total-num{display:inline-block;font-size: 14px;line-height: 160%;letter-spacing: 0.135px;}
  @include media-breakpoint-down(md){
    margin:0;
    .scroll-tab{margin-bottom:24px;}
    .total-num{display:none;}
  }
}
.faq-accordion{
  margin-bottom:140px;
  .accordion-button{
    display: flex;align-items: start;position:relative;padding:24px 0;font-size: 18px;font-weight: 700;line-height: 160%;width: 100%;text-align: left;
    &::after{position:absolute;right:0;top:50%;transform:translateY(-50%);-webkit-transform:translateY(-50%);display:inline-block;width: 25px;height: 25px;content: "";background: url("../images/icon-faq-arr.svg")no-repeat;transition: transform .3s;}
    &:not(.collapsed)::after{transform:translateY(-50%) rotate(-180deg);-webkit-transform:translateY(-50%) rotate(-180deg);}
    p{width: calc(100% - 82px);margin-right:40px;word-break: break-all;}
  }
  .cate{display:inline-block;margin-right:12px;text-align:center;min-width:70px;color:$primary;font-size: 18px;font-weight: 700;line-height: 160%;letter-spacing: 0.135px;}
  .answer-content{padding:24px 80px 24px 24px;border:1px solid $primary;border-radius: 8px;background: rgba(9, 101, 238, 0.06);}

  @include media-breakpoint-down(md){
    margin-bottom:0;
    .accordion-button{
      font-size: 14px;line-height: 160%;
      &::after{width:20px;height:20px;background-size: 100%;}
      p{width: calc(100% - 68px);margin-right:30px;}
    }
    .cate{min-width:60px;font-size: 14px;line-height: 160%; /* 22.4px */letter-spacing: 0.135px;}
    .answer-content{padding:24px;font-size:14px;}
  }

}

//download
.down-list{
  display:flex;flex-direction:column;row-gap:24px;
  li{
    padding:24px 0;
    >a{display:flex;flex-direction:column;align-items: flex-start;row-gap:16px;
    .cate{color:#1535AD;font-weight: 700;line-height: 140%; }
    .title-line{
      width:100%;display:flex;align-items:center;
      .noti{padding:5px 10px;background-color: $primary;color:$white;font-size:14px;margin-right:16px;border-radius:4px;}
      .title{width: calc(100% - 61px);color:$dark;font-size: 24px;font-weight: 700;line-height: 29px;text-overflow: ellipsis;overflow: hidden;white-space: nowrap;}
      }
      .desc{color:$gray-6;}
      &:hover .title-line .title{text-decoration: underline;}
    }
      
    }
    .file-line{
      display:flex;justify-content:space-between;align-items:center;margin-top:16px;
      a{display:flex;align-items:center;color:#999;column-gap:8px;}
      .date{color:#999;}
    }
    @include media-breakpoint-down(md){
      row-gap:0;
      li{
        >a{row-gap:8px;
          .cate{font-size:14px; }
          .title-line{
            flex-direction:column;align-items: flex-start;row-gap: 8px;
            .noti{font-size:12px;margin-right:0;letter-spacing: -0.06px;}
            .title{width:100%;font-size: 15px;line-height: 24px;-webkit-box-orient: vertical;display: -webkit-box;white-space: inherit;-webkit-line-clamp: 2;word-break: break-all;
              &.active{}
            }
          }
          .desc{font-size:14px;}
        }
        .file-line{
          margin-top:8px;
          a{font-size:13px;}
          .date{font-size:13px;}
        }
      }
    }
  }

  .error-page{
    display: flex; flex-direction: column; gap: 8px; align-items: center;
    justify-content: center; text-align: center;
    .img{
      margin-bottom: 22px;
    }
    .title{
      font-size: 32px; color: #000; font-weight: 700; line-height: 130%;
    }
    .desc{
      font-size: 15px; color: #000; font-weight: 500; line-height: 160%;
    }
    .example{
      font-size: 14px; color: #999; line-height: 160%;
    }
    .btn{ 
      margin-top: 22px;
    }
  }
