html.bg-gray{
  height: 100%; background: $bg-color;
  body{ height: 100%;}
}
#wrap{
  &.login{
    padding-top: 0; 
    @include media-breakpoint-down(md){
      height: 100%;background:$white;
    }
  }
}


//  로그인 -------
.sign-container{
  width: 100%; height: 100%;max-width: 560px; margin: 154px auto 0;background: #fff;
  .content{
    width: 100%; margin: 0 auto; background-color: $white; border: 1px solid $border-color; 
  }
  
  @include media-breakpoint-down(md){
    margin: 0 auto;padding-top: 74px;
    .content{
      border:0;
    }
  }

  // 전페이지 공용 ---
  .top-head{
    display: flex; align-items: center; justify-content: center;padding: 70px 80px 0;margin-bottom:80px;
    .sign-title{
      font-size: 32px;font-weight: 700;line-height: 160%;text-transform: uppercase;
    }
    @include media-breakpoint-down(md){
      padding:0 16px 0;margin-bottom:53px;
      .sign-title{
        font-size: 24px;
      }
    }
  }


  // 전페이지 공용 end ---
  .sign-content{
    padding: 0 16px;
    @at-root{
      .input-form{
        padding-top: 16px;
        &:first-child{padding-top:0;}
      }
    }
    .login-btn{width: 100%;margin-top:60px;}
    .save-login{
        margin-top: 10px;
      .form-check-label{color:$gray-6;}
      }
    @include media-breakpoint-up(lg){
      padding: 0 80px 80px;
      .save-login{
        margin-top: 14px;
      }
    }
    .link-login{
      margin-top: 24px; display: flex; align-items: center; justify-content: center; gap: 33px;
      li{
        position: relative;
        a{
          font-size: 14px;font-weight:500;color:$gray-6;
        }
        &+li{
          &::after{ 
            content: ""; position: absolute; width: 1px; height: 10px; background-color: $gray; left: -16px; top: 50%; transform: translateY(-50%);
          }
          
        }
      }
      
    }
    
    .login-type{
      margin-top: 40px;
      ul{
        display: flex; gap: 12px;
        li{
          a{
            display: flex;align-items: center;justify-content: center;column-gap:8px; 
          }
          &.kakao{
            flex: 1 0 0;
            a{padding: 9.46px 24px; font-size: 14px;font-weight: 600;line-height: 140%; letter-spacing: -0.07px;background-color: #fee500;border-radius:40px; }
          }
          &.naver{
            a{ width:44px;height:44px;border-radius:100%;background-color: #00c43b;}
          }
          &.google{
            a{width:44px;height:44px;border-radius:100%; background-color: $white;border:1px solid $border-color;}
          }
        }
        .icon-kakao{width:27px;height:26px;background:url("../images/icon-kakao.png");}
        .icon-naver{width:20px;height:18px;background:url("../images/icon-naver.png");}
        .icon-google{width:26px;height:26px;background:url("../images/icon-google.png");}
      }
      @include media-breakpoint-down(md){
        ul{
          gap:8px;
          li{
            &.kakao{
              a{padding: 13px 10px; font-size: 13px;}
            }
          }
        }
      }
    }
    .btn-login{margin-top:40px;width:100%;}
    .info-text{
      display:flex;align-items:center;margin:40px 0;text-align:center;flex-direction:column;row-gap:8px;padding:12px;font-size:14px;border-radius: 8px;background: rgba(0, 35, 131, 0.04);
      strong{font-size: 16px;font-weight:700;}
      &.login-text{background-color:$bg-color;border-radius:0;margin:40px 0 0;
        strong{color:$gray-6;}
      }
      &.success-text{display:block;padding:32px 12px;margin:60px 0 0;color:$dark;font-size:16px;
        strong{font-size:18px;color:$primary;font-weight:400;}
        }
    }
  }
  // ID 찾기 완료
  .find-result{
    text-align: center; margin-top:-40px;
    .icon-find{width:160px;height:160px;background:url("../images/icon-find.svg");}
    .success-text{margin:60px 0 40px;}
    .login-btn{margin-top:40px;}
  }
}







// 회원가입
.signup-title{
  font-size: 20px; font-weight: 700; margin-top: 40px;
}
.radio-form{
  margin-top: 10px;
}

.agree-form{
  .all-check{
    margin-top: 15px;
    .form-check-input{ width: 24px; height: 24px; }
    .form-check-label{ font-size: 16px; }
  }
  .desc{
    font-size: 12px; margin-top: 10px;
  }
  .agree-content{
    margin-top: 20px;
    .txt-box{
      margin-top: 10px; border: 1px solid $border-color; padding: 10px 15px;
      height: 100px; overflow: auto; font-size: 12px;
    } 
  }
}
.icon-warning{width:16px;height:17px;margin-right:4px;background:url("../images/icon-warning.svg");}