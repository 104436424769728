.modal-header{
  .btn{padding:0;}
}
.modal-title{
  font-size: 18px; font-weight: 700;
}
.modal-content{
  .text {
    margin: 10px 0;
    @include media-breakpoint-down(lg) {
      font-size: 14px;
    }
  }
  .btn-group{
    display: flex; justify-content: center; flex-direction: row; gap: 10px; margin: 20px 0;
  }
}

.receipt-info {
  border-bottom: 1px solid #eee; padding: 9px 0;
  li { 
    display: flex; align-items: center; justify-content: space-between; font-weight: 500; line-height: 1.3; font-size: 15px; padding: 10px 0; 
    p {color: #666;}
  }
  &.total-price {
    p {font-size: 16px; font-weight: 600; color: #000;}
    span {font-size: 16px; font-weight: 700; color: $danger-dark;}
  }
}
.receipt-txt {font-size: 14px; line-height: 1.3; color: #999; margin: 19px 0 34px;}

.estimate-table {
  max-height: 693px;
  .estimate-number {padding: 10px 0; font-size: 14px; font-weight: 500; color: #666;}
  .estimate-title {text-align: center; color: #000; font-size: 36px; font-weight: 700; line-height: 1.3; margin-bottom: 32px;}
  .estimate-info {
    display: flex; gap: 60px; margin-bottom: 44px;
    .left {
      flex: 1 0 auto;
      .top-txt {display: flex; flex-direction: column; gap: 16px;}
      .name {display: flex; justify-content: space-between;}
      padding: 10px 0; display: flex; flex-direction: column; justify-content: space-between; font-size: 15px; color: #000; font-weight: 500; line-height: 1.3;
    }
  }
  .table {
    border: 1px solid #ebebeb; font-size: 14px; letter-spacing: -0.28px; text-align: center;
    th {
      border: 1px solid #ebebeb; background-color: #f7f7f7; font-weight: 500; padding: 4px 18px;
      &.producer {padding: 0 9px; width: 5%;}
    }
    td {border: 1px solid #ebebeb; color: #000; padding: 4px 18px; word-break: break-all;}
  }
  @include media-breakpoint-down(md){
    width: 800px;
	}
}
.price-table {
  border-top: 1px solid #eee; border-bottom: 1px solid #eee; text-align: center; width: 100%; height: 48px; margin-bottom: 16px;
  th,td {padding: 0 8px; color: #000; font-size: 14px; font-weight: 500; letter-spacing: -0.28px;}
  span {font-weight: 600;}
  .total-price {
    color: $danger-dark; font-size: 18px; letter-spacing: -0.36px; font-weight: 700;
    span {font-weight: 700; font-size: 14px;}
  }
}
.product-table {
  width: 100%; margin-bottom: 16px;
  
}