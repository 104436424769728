.navigation{
    display: flex;flex-wrap: wrap; align-items: center; justify-content: center; margin:100px 0 40px; gap: 4px;
    .page-prev, .page-next{
        a{padding: 0;}
    }
    .pagination{
        display: flex; align-items: center; gap: 4px;
    }
    a{ 
      width: 34px; height: 34px;border-radius:100%;display: flex; align-items: center; justify-content: center; font-size: 14px; font-family:'Pretendard';
      &.active{ background-color:$dark; color:#fff;
        &:hover{background-color:$dark;}
        }
      &:hover{background-color: $border-color;}
    }
    @include media-breakpoint-down(md){
        gap: 2.75px; margin:40px 0;
        .pagination{
            gap:2.75px;
        }
    }
}
