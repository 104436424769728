.certification-wrap{
  .certification-list{
    margin-top: 80px; display: flex; gap: 48px; flex-flow: wrap;
    @include media-breakpoint-down(lg){
     margin-top: 24px; gap: 16px;
    }
    .certification-content{
      width: calc(25% - 36px); margin-bottom: 32px; position: relative;
      @include media-breakpoint-down(lg){
        width: calc(50% - 8px); margin-bottom: 8px;
      }
      .img{ 
        width: 100%; height: 372px; border: 1px solid #eee; position: relative;
        img{ width: 100%; height: 100%; object-fit: cover;}
        @include media-breakpoint-down(lg){
          height: auto;
        }
      }
      .title{font-size: 16px; font-weight: 700; line-height: 160%;  color: #666; text-align: center; margin-top: 16px;}
      .number{font-size: 16px; line-height: 160%;  color: #666; text-align:  center;}
      @include media-breakpoint-down(lg){
        .title{font-size: 14px; margin-top: 8px;}
        .number{font-size: 14px;}
      }
      &.active{
        .hover-txt{
          visibility: inherit;
        }
      }
      .hover-txt{
        position: absolute; width: 100%; height: 100%; top: 0; left: 0; background-color: rgba(9,101,238,0.9); display: flex; align-items: center; justify-content: center;visibility: hidden;
        p{ font-size: 18px; color: #fff; text-align: center; padding: 0 26px;}
      }
    }
  }

}