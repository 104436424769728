/* checkbox/radio */
.form-check {
  display: flex;
  align-items: center;
   min-height: 16px;
  padding-right: 24px;
  &.block{
    display: block; text-align: center; padding-right: 0;
    .form-check-label{
      display: block; margin-left: 0;
      p{ font-size: 15px; margin: 5px 0;}
    }
  }
}

.form-check-input {
  width: 18px;
  height: 18px;
  margin-top: 1px;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  appearance: none;
  cursor: pointer;


  &[type="checkbox"] {
    background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5 9.52389L8.80952 13.3334L15 6.66675' stroke='%23CCCCCC' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Crect x='0.5' y='0.5' width='19' height='19' rx='3.5' stroke='%23CCCCCC'/%3E%3C/svg%3E%0A");
  }

  &[type="radio"] {
    border-radius: 50%;  border: 1px solid #eee;
  }

  &:checked {
    &[type="checkbox"] {
      background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='20' height='20' rx='4' fill='%23222222'/%3E%3Cpath d='M5 9.52389L8.80952 13.3334L15 6.66675' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    }

    &[type="radio"] {
        background-color: #fff; border: 7px solid $primary; position: relative;
      ~ .form-check-label{color: $primary;}
    }
  }

  &:disabled {
    pointer-events: none;
    filter: none;
    opacity: .5;
  }

  &[disabled],
  &:disabled {
    ~ .form-check-label {
      opacity:.5;
    }
  }
}
.check-badge{
  display: none;
  &+.check-badge-label{
     border: 1px solid #aaa; font-size: 14px; color: #aaa; padding: 6px 10px; text-align: center; background-color: #fff; cursor: pointer;
    }
  &:checked{ 
    &+.check-badge-label{
      border: 1px solid #000; color: #000;
    }
  }
}
.inline-form-radio{
  .form-check{ margin-right: 40px;}
}

.form-check-label {
  font-size: 14px;
  font-weight: 500;
  margin-left: 8px;
  vertical-align: sub;
  cursor: pointer;
}

/* switch */
.form-switch {
  padding-left: 56px;

  .form-check-input {
    width: 48px;
    margin-left: -56px;
    background-color: #b9b9b9;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
    background-position: left center;
    border-radius: 3em;
    transition: background-position 0.15s ease-in-out;

    &:checked {
      background-position: right center;
      background-color: $primary;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
    }
  }

  &.label-empty{
    padding-left: 48px;
    .form-check-input{
      margin-left: -48px;
      margin-top: 0;
    }
  }
}

.form-check-inline {
  display: inline-flex;
  margin-right: 1rem;
  vertical-align: middle;
  align-items: center;
}

@media screen and (max-width: 992px) {
  .inline-form-radio{
    .form-check{ margin-right: 0;}
  }
  .form-check-input{
    width: 18px; height: 18px;
  }
  .form-check-label{ font-size: 13px; line-height: 1.7;}
}