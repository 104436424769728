.text-dark{ color: #222 !important;}
@include media-breakpoint-down(lg){
  .m-b-1{ margin-bottom: 8px;}
  .m-b-2{ margin-bottom: 16px;}
  .m-b-3{ margin-bottom: 24px;}
}
// 공용
.product-intro-section{
  padding: 120px 0;
  &.bg-gray{ background-color: #f7f7f7;}
  @include media-breakpoint-down(lg){
    padding: 70px 0;
  }
  &.main-visual-head{
    padding: 200px 0 250px; overflow-x: hidden;
    @include media-breakpoint-down(xl){
      padding: 150px 0 100px;
    }
    @include media-breakpoint-down(lg){
      padding: 70px 0;
    }
  }
}
.product-intro__title{
  font-size: 32px; font-weight: 700; line-height: 160%; 
  &.width-text{margin-bottom: 60px;}
  &.flex{
    display: flex; align-items: center; gap: 16px;
    .sub{font-size: 24px; color: #999;}
    @include media-breakpoint-down(lg){
      .sub{font-size: 14px;}
    }
  }
  @include media-breakpoint-down(lg){
    font-size: 24px;
    &.width-text{margin-bottom: 40px;}
  }
}
.product-intro__desc{
  font-size: 18px; margin-top: 24px;
  @include media-breakpoint-down(lg){
    font-size: 15px;
  }
}

.visual-head{
  width: 100%; display: flex; position: relative;
  @include media-breakpoint-down(lg){
    flex-direction: column; gap: 24px;
  }
  .visual-head__content{
    width: 45%;
    @include media-breakpoint-down(lg){
      width: 100%;
    }
    .title{
      font-size: 74px; font-weight: 700; line-height: 130%;
      @include media-breakpoint-down(lg){
        font-size: 40px;
      }
    }
    .desc{
      font-size: 24px; font-weight: 700; line-height: 160%; margin-top: 60px;
      @include media-breakpoint-down(lg){
        font-size: 18px; margin-top: 40px;
      }
    }
    .txt{
      font-size: 18px; margin-top: 24px; line-height: 160%;
      @include media-breakpoint-down(lg){
        font-size: 15px;
      }
    }
    .img{
      margin-top: 60px;
      @include media-breakpoint-down(lg){
        margin-top: 30px;
      }
    }
    .visual-head__list{
      margin-top: 24px;
      .list-title{
        font-size: 18px; font-weight: 700; line-height: 160%; color: #000;
        @include media-breakpoint-down(lg){
          font-size: 16px;
        }
      }
      ul{
        margin-top: 16px; display: flex; flex-direction: column; gap: 16px;
        @include media-breakpoint-down(lg){
          gap: 8px;
        }
        li{
          padding-left: 10px; position: relative; color: #000; line-height: 160%;
          &::before{
            content: ""; position: absolute; left: 0; top: 10px; width: 4px; height: 4px; background-color: #000; border-radius: 50%;
          }
          @include media-breakpoint-down(lg){
            font-size: 14px;
          }
        }  
      }
    }
  }
  .visual-img{
    position: absolute; top: -30%; right: -20%;
    &.ade{
      top: -10%;
    }
    @include media-breakpoint-down(xl){
      position: static;
    }
    @include media-breakpoint-down(xl){
      width: 70%; margin-left: auto;
    }
  }
}
.slide-img-box{
  &.chakra{ margin-top: 84px;}
  @include media-breakpoint-down(lg){
    overflow: scroll; height: 250px;
    img{ max-width: inherit; height: 100%;}
    &.chakra{ margin-top: 40px;}
  }
}
.shadow-box{
  box-shadow: 0px 4px 18px 0px rgba(0, 0, 0, 0.10); border-radius: 16px;
  padding: 40px 32px; 
  @include media-breakpoint-down(lg){
    padding: 16px;
  }
  .img{ margin-bottom: 16px;}
  .title{
    color: #000; font-size: 20px; font-weight: 700;
    line-height: 160%
  }
  .desc{
    font-size: 18px;line-height: 160%; margin-top: 12px; color: #666;
    &.text-dark{color: #222;}
  }
  ul{
    margin-top: 10px; display: flex; flex-direction: column; gap: 10px;
    li{
      padding-left: 10px; position: relative; color: #666; line-height: 160%;
      &::before{
        content: ""; position: absolute; left: 0; top: 10px; width: 4px; height: 4px; background-color: #666; border-radius: 50%;
      }
      @include media-breakpoint-down(lg){
        font-size: 14px;
      }
    } 
    &.none-dot{
      li{
        padding-left: 0;
        &::before{content: none;}
      }
    } 
  }
  @include media-breakpoint-down(lg){
    .img{ 
      margin-bottom: 12px;
      img{width: 32px; height: 32px;}
    }
    .title{ font-size: 16px; }
    .desc{font-size: 14px; margin-top: 6px;}
  }
}

// orange
.product-list-box{
  padding: 20px 29px; box-shadow: 0px 4px 18px 0px rgba(0, 0, 0, 0.10); border-radius: 24px;
  .title{
    font-size: 32px; font-weight: 700; line-height: 160%; text-align: center;
  }
  ul{
    margin-top: 22px; display: flex; flex-direction: column; gap: 8px;
    li{ 
      border: 1px dashed $primary; padding: 8px 0; color: $primary;font-size: 17px; font-weight: 700; line-height: 160%; text-align: center; border-radius: 8px;
    }
  }
}
.product-intro__list{
  margin-bottom: 24px; margin-top: 12px;
  &:last-child{margin-top: 0;}
  p{
    font-size: 18px; line-height: 160%;margin-bottom: 5px;
    @include media-breakpoint-down(lg){
      font-size: 16px; 
    }
  }
  ul{
    display: flex; flex-direction: column; gap: 16px;
    &.gap-0{ gap: 0;}
    &.gap-6{ gap: 6px;}
    li{
      position: relative; padding-left: 15px;
      &::before{
        content: ""; position: absolute; left: 0; top: 10px; width: 4px; height: 4px; background-color: #000; border-radius: 50%;
      }
      @include media-breakpoint-down(lg){
        font-size: 15px;
      }
    }
  }
}
.oracle-shadow{
  display: flex; flex-wrap: wrap; gap: 32px;
  @include media-breakpoint-down(lg){
    gap: 16px;
  }
  .shadow-box{
    width: calc(50% - 16px);
    @include media-breakpoint-down(lg){
      width: 100%;
    }
  }
}

.product-tab-content{
  .table{
    margin: 50px 0;
    @include media-breakpoint-down(lg){
      margin: 25px 0;
    }
  }
}
.tab-list{
  margin-top: 40px; display: flex;flex-direction: column; gap: 16px;
  @include media-breakpoint-down(lg){
    margin-top: 20px; gap: 8px;
  }
  li{
    padding-left: 10px; position: relative; color: #000; line-height: 160%;
    &::before{
      content: ""; position: absolute; left: 0; top: 10px; width: 4px; height: 4px; background-color: #000; border-radius: 50%;
    }
    @include media-breakpoint-down(lg){
      font-size: 14px;
      &::before{top: 8px;}
    }
    .list-sub{
      margin-top: 10px; color: #666;
      @include media-breakpoint-down(lg){
       font-size: 12px;
      }
    }
    >ul{
      li{
        font-size: 16px; color: #666;
          @include media-breakpoint-down(lg){
            font-size: 12px;
          }
        &::before{
          content: none;
        }
      }
    }
  }
}

.badge-content{
  margin-bottom: 40px;
  &.badge-content{
    margin-bottom: 100px;
  }
  &.flex{ 
    display: flex; align-items: center; justify-content: space-between;
    .badge-list{
      width: 80%;
    }
    .img{
      flex-shrink: 0;
    }
  }
  &:last-child{margin-bottom: 0;}
  &.sub{
    .content{
      margin-top: 32px;
      @include media-breakpoint-down(lg){
        margin-top: 24px;
      }
    }
    .product-intro__subtitle{
      font-size: 20px; font-weight: 700; line-height: 32px;
      @include media-breakpoint-down(lg){
        font-size: 16px;
      }
    }
    .badge-list{
      margin-top: 16px;
      @include media-breakpoint-down(lg){
        margin-top: 8px;
      }
    }

  }
  .badge-list{
    width: 100%; margin-top: 32px; display: flex; flex-wrap: wrap; gap: 12px;
    .badge{
      padding: 8px 16px; border-radius: 30px; background-color: #F7F7F7;
      font-size: 16px; line-height: 160%; color: #333;
    }
    @include media-breakpoint-down(lg){
      width: 100%;
      .badge{ font-size: 14px;}
    }
  }
  .operating-environment{
    display: flex; gap: 12px; margin-top: 32px;
    @include media-breakpoint-down(lg){
      flex-direction: column;
    }
    &__box{
      &.w-70{ 
        width: 70%;
      }
      &.w-30{ 
        width: 30%;
      }
      @include media-breakpoint-down(lg){
        &.w-70{ 
          width: 100%;
        }
        &.w-30{ 
          width: 100%;
        }
      }
    }
    &__title{
      font-size: 18px; font-weight: 700; padding: 4px 32px; background-color: #eee; border-radius: 16px 16px 0 0;
      @include media-breakpoint-down(lg){
        font-size: 16px; padding: 4px 16px;
      }
    }
    &__desc{
      height:100%; padding: 35px 32px;  display: flex; align-items: center; gap: 62px; background-color: #f7f7f7; border-radius: 0 0 16px 16px;
      @include media-breakpoint-down(lg){
        flex-direction: column; gap: 30px; align-items: flex-start; padding: 16px; font-size: 14px;
      }
    }
    .environment-title{
      font-weight: 700; margin-bottom: 10px;
    }
  }
}

// log-catch
.product-flex{
  @include media-breakpoint-down(lg){
    .row{ gap: 40px;}
    .od-1{ order: 1;}
    .od-2{ order: 2;}
  }
}

.dot-border-list{
  display: flex; flex-flow: wrap; gap: 24px; margin: 40px 0 60px;
  @include media-breakpoint-down(lg){
    gap: 12px;
  }
  .dot-border{
    width: calc(20% - 20px); height: 190px; padding: 24px 16px; display: flex; flex-direction: column; gap: 16px; border: 1px dashed $primary; border-radius: 24px;
    .desc{
      color: #000; text-align: center; line-height: 160%
    }
    @include media-breakpoint-down(lg){
      width: calc(50% - 12px); height: 156px; padding: 16px 12px;
      .img{
        img{ width: 40px; height: 40px;}
      }
      .desc{ font-size: 14px;}
    }
  }
}
.logcatch-shadow{
  display: flex; flex-wrap: wrap; gap: 32px;
  @include media-breakpoint-down(lg){
    gap: 16px;
  }
  .shadow-box{
    width: calc(33.3% - 21px); min-height: 340px;
    @include media-breakpoint-down(lg){
      width: 100%; min-height: 230px;
    }
  }
}
.text-box{
  .product-intro__list{
    margin-top: 24px;
    ul{
      li{
        position: relative; padding-left: 15px;
        &::before{
          content: ""; position: absolute; left: 0; top: 10px; width: 4px; height: 4px; background-color: #000; border-radius: 50%;
        }
        @include media-breakpoint-down(lg){
          font-size: 15px;
        }
      }
    }
  }
}
.dotted-graph{
  display: flex; gap: 40px; margin-top: 40px;
  @include media-breakpoint-down(lg){
   gap: 25px;
  }
  li{
    width: 97px; height: 97px; border-radius: 50%; border: 1px dotted $primary;
    display: flex; align-items: center; justify-content: center; font-weight: 700;line-height: 140%; color: $primary; text-align: center; position: relative;
    &::after{
      content:""; width: 39px; border-top: 1px dotted $primary; position: absolute; right: -40px; top: 50%; transform:translateY(-50%);
    }
    &:last-child{
      &::after{content:none;}
    }
    @include media-breakpoint-down(lg){
      width: 62px; height: 62px; font-size: 13px; 
      &::after{
        width: 24px; right: -25px;
      } 
    }
  }
}

// chakra max
.features-list{
  .features-box{
    position: relative; width: 75%; margin-top: 84px; background-color: #fff;
    @include media-breakpoint-down(lg){
      width: 100%; margin-top: 100px;
    }
    &.mirror{
      margin-left: auto;
      .num{
        left: inherit; right: 0;
      }
    }
    .num{
      position: absolute; top: -65px; left: -25px; z-index: -1;
      @include media-breakpoint-down(lg){
        left: 0;
      }
    }
    .title{
      font-size: 24px; font-weight: 700; line-height: 160%;
      @include media-breakpoint-down(lg){
        font-size: 18px;
      }
    }
    .desc{
      font-size: 18px; line-height: 160%; margin-top: 16px;
      @include media-breakpoint-down(lg){
        font-size: 14px;
      }
    }
    .product-intro__list{
      margin-top: 16px;
      ul{
        li{
          position: relative; padding-left: 15px;
          &::before{
            content: ""; position: absolute; left: 0; top: 10px; width: 4px; height: 4px; background-color: #000; border-radius: 50%;
          }
          @include media-breakpoint-down(lg){
            font-size: 14px;
          }
        }
      }
    }
  }
}
.dashed-tab{
  padding: 12px; border-radius: 8px; border: 1px dashed #000; margin-top: 60px;
  display: flex; flex-direction: column; gap: 12px;
  @include media-breakpoint-down(lg){
    gap: 6px; margin-top: 30px;
  }
  .main-tab{
    .btn{ 
      width: 100%; font-size: 18px; font-weight: 700; line-height: 160%; text-align: center; border: 1px dashed #000; padding: 11px 15px; 
      border-radius: 8px;
      @include media-breakpoint-down(lg){
        font-size: 12px; padding: 8px 4px;
      }
      &.active{
        border: 1px dashed #0965EE;  background: linear-gradient(0deg, rgba(255, 255, 255, 0.90) 0%, rgba(255, 255, 255, 0.90) 100%), #0965EE;
      }
    }
  }
  .sub-tab{
    display: flex; flex-wrap: wrap; gap: 12px;
    @include media-breakpoint-down(lg){
      gap: 6px;
    }
    .btn{ 
      width: calc(33.3% - 8px); font-size: 18px; font-weight: 700; line-height: 160%; text-align: center; border: 1px dashed #000; padding: 11px 15px; border-radius: 8px; line-height: 1.1;
      @include media-breakpoint-down(lg){
        font-size: 12px; padding: 8px 4px; width: calc(33.3% - 4px);
      }
      &.active{
        border: 1px dashed #0965EE;  background: linear-gradient(0deg, rgba(255, 255, 255, 0.90) 0%, rgba(255, 255, 255, 0.90) 100%), #0965EE;
      }
    }
  }
}
//Orange Ade


//Trusted Orange
.trusted-section{
  .nav-tabs{
    margin-top: 60px;
    @include media-breakpoint-down(lg){
      margin-top: 30px;
    }
  }
  .tab-content__box{
    display: flex; gap: 24px;margin-top: 60px;
    @include media-breakpoint-down(lg){
      margin-top: 25px;
    }
  }
  .tab-content__list{
    margin-top: 24px;
    .list-title{
      font-size: 18px; font-weight: 700; line-height: 160%; color: #000;
      @include media-breakpoint-down(lg){
        font-size: 16px;
      }
    }
    ul{
      margin-top: 16px;
      li{
        padding-left: 10px; position: relative; color: #000; line-height: 160%;
        &::before{
          content: ""; position: absolute; left: 0; top: 10px; width: 4px; height: 4px; background-color: #000; border-radius: 50%;
        }
        @include media-breakpoint-down(lg){
          font-size: 14px;
        }
      }  
    }
  }
}

//Peta SQL

.peta-shadow{
  display: flex; flex-wrap: wrap; gap: 32px; margin-top: 60px;
  @include media-breakpoint-down(lg){
    gap: 16px; margin-top: 30px;
  }
  .shadow-box{
    width: calc(20% - 26px); min-height: 240px; padding: 32px;
    @include media-breakpoint-down(lg){
      width: 100%; min-height: 130px;
    }
    .desc{
      font-size: 16px; font-weight: 700;
      @include media-breakpoint-down(lg){
        font-size: 14px;
      }
    }
  }
}
.table{
  &.normal{
     margin: 0;
    th{ background-color: #eee;}
    th,td{ padding: 11px 28px; border-bottom: 1px solid #eee;}
  }
}
.product-specification-content{
  margin-bottom: 60px;
  @include media-breakpoint-down(lg){
    margin-bottom: 0; margin-top: 30px;
  }
}
.system-container{
  margin-bottom: 200px;
  @include media-breakpoint-down(lg){
    margin-bottom: 70px;
  }
}
.db-container{
  margin-bottom: 60px;
  &:last-child{
    margin-bottom: 0;
  }
}
.db-compliance{
  display: flex; flex-direction: column; gap: 6px;
}
.db-box{
  padding: 16px 0; background-color: #F7F7F7; border-radius: 16px;
  display: flex; align-items: center;
  .key{
    font-size: 18px; color: #000; line-height: 160%; position: relative;
    padding: 0 40px;flex-shrink: 0;
    @include media-breakpoint-down(lg){
      font-size: 14px; padding: 0 20px;
    }
    &::after{
      content: ""; position: absolute; width: 1px; height: 36px; background: #ccc; right: 0; top: 50%; transform: translateY(-50%);
      @include media-breakpoint-down(lg){
        height: 24px;
      }
    }
  }
  .value{
    font-size: 14px; color: #666; line-height: 160%; padding: 0 13px 0 40px;
    @include media-breakpoint-down(lg){
      font-size: 12px; padding: 0 13px 0 20px;
    }
  }
}