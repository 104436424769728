/*.content-head {
  width: 100%; display: flex; flex-direction: column; align-items: center;
  &.order-complete {padding: 280px 0;}
  .content-title {
    font-size: 40px; font-weight: 700; text-align: center; line-height: 140%; color: #000;
  }
  @include media-breakpoint-down(lg) {
    padding: 40px 0;
    .content-title{font-size: 28px;}
  }
}*/

//content-head
.content-head{
  display:flex;flex-direction:column;align-items:center;margin-bottom:160px;
  &.bg{
    background: linear-gradient(180deg, rgba(249, 249, 249, 0) 0%, #E9EBF4 88.02%);overflow: hidden;
  }
  
  h1{font-size: 80px;font-weight: 700;line-height: 130%;margin-bottom:33px;text-transform: capitalize;}
  .text{color:$gray-6;font-size: 18px;line-height: 160%;text-align:center;
    strong.text-primary{display:block;margin-bottom:14px;}
  }
  @include media-breakpoint-down(md){
    margin-bottom:80px;
    h1{font-size: 32px;line-height: 120%;margin-bottom:24px;}
    .text{font-size: 15px;
      strong.text-primary{margin-bottom:27px;}
    }
    .sub-visual{height: 288px;display: flex;align-items: self-end;}
  }
}