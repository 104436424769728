.header{
  width: 100%;  display: flex; align-items: center; justify-content: space-between; position:fixed; top: 0; left: 0;  z-index: 9999; padding: 17px 80px;
  @include media-breakpoint-down(xl){
    padding: 12px 16px;
    .logo{
      height: 26px; 
      img{ width: 100%; height: 100%; object-fit: contain;}
    }
  }
  nav{
    position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);
    ul{
      display: flex; align-items: center; gap: 50px;
      li{
        a{font-size: 16px; font-weight: 700; line-height: 160%;}
      }
    }
    @include media-breakpoint-down(xl){
     display: none;
    }
  }
  .tool-box{
    display: flex; align-items: center; gap: 32px;
    @include media-breakpoint-down(xl){
      gap: 16px;
    }
    .sns{
      display: flex; align-items: center;gap: 12px;
      @include media-breakpoint-down(xl){
       display: none;
      }
    }
    .info-form{
      display: flex; align-items: center; gap: 25px;
      .login{ font-size: 14px;}
      .select-wrap{ 
        width: 50px; font-size: 14px;
        .select-btn{ padding: 0;}
        .optionlist{
          padding: 0; border-radius: 8px; width: 60px;
          .optionitem{ padding: 8px; margin-top: 0; text-align: center;}
        }
      } 
      @include media-breakpoint-down(xl){
      }
    }
  }
}


// 메뉴버튼 (펼쳤을때)
.full-screen-menu{
  width: 100%; height: 100vh; display: none;  position: fixed; top: 0; right: 0; z-index: 9999; background-color: $primary; color: #fff; 
  &.active{ display: block;}
  nav{
    ul{
      li{
        a{color: #fff;}
      }
    }
  }
  .tool-box{
    .info-form{
      .login{ color: #fff;}
      .select-wrap{ 
        width: 50px; font-size: 14px;
        .select-btn{color: #fff;}
        .optionlist{
          padding: 0; color: #000;
          .optionitem{ padding: 8px; margin-top: 0;}
        }
      } 
    }
  }
  .menu{
    width: 100%; height:100%; display: flex; flex-direction: column; align-items: center; justify-content: center;
    ul{
      display: flex; flex-direction: column; gap: 32px; text-align: center;
      @include media-breakpoint-down(xl){
        gap: 16px;
      }
      li{
        a{
          font-family: Montserrat; font-size: 70px; font-weight: 700; line-height: 130%; color: #fff; position: relative;
          &:hover{
            &::after{
              content: ""; width: 24px; height: 24px; background-color: #B3F200; border-radius: 50%; display: block; position: absolute; top: 15px; right: -30px;
            }
          }
          @include media-breakpoint-down(xl){
            font-size: 32px; line-height: 160%;
            &:hover{
              &::after{
                content: none;
              }
            }
          }
        }
      }
    }
  }
  .btn-list{
    display: flex; align-items: center; gap: 16px; margin-top: 5rem;
    .icon-w-blog{width: 40px; height: 40px;}
    .icon-w-youtube{width: 40px; height: 40px;}
  }
}
$gray-6: #666;
