//dep2 공통
.dep2{
  padding-top:24px;
  ul{
    column-gap:12px;justify-content: center;display: flex;
    li{
      a{display:flex;align-items:center;justify-content:center;border-radius:24px;padding:8px 16px;color:$dark;font-size:15px;line-height:24px;background-color: $bg-color;
        &.active{
          background-color: $primary;color:$white;font-weight:700;
        }
      }
    }
  }
  @include media-breakpoint-down(md){
    padding-top:16px;overflow-x: scroll;overflow-y: hidden;-ms-overflow-style: none;scrollbar-width: none;
    &::-webkit-scrollbar{display:none;}
    ul{
      width:auto;white-space: nowrap;overflow-x: auto;justify-content: start;flex-wrap: nowrap;
      li{
        display: inline-block;text-align: center;flex: 0 0 auto;
        a{
          padding:7px 16px;font-family: Pretendard;font-size: 13px;font-weight: 500;line-height: 21px;
        }
      }
    }
  }
}
//scroll-bar 공통
.scroll-bar{
  overflow-y:auto;padding:0 8px 0 0;
  &::-webkit-scrollbar{width:4px;background-color: $border-color;}
  &::-webkit-scrollbar-thumb {background-color:#999999}
}

//search-box 공통
.search-box{
  display: flex;align-items: center;margin-bottom:80px;padding:8px 0;border-bottom:1px solid $border-color;
  input{
    width:80%;border:0;
  }
}
@include media-breakpoint-down(md){
  .search-box{
    margin-bottom:24px;
    input{
      width:100%;
    }
  }
}

//commnunity
.commu-title-box{
  .commu-title{
    display:flex;column-gap:24px;align-items: flex-start;
    h2{width:calc(100% - 184px);padding-bottom:40px;font-size: 40px;font-weight: 700;line-height:52px;border-bottom:2px solid $dark;}
    &.commu-read-title{
      h2{width:100%;}
    }
    .icon-community{margin-top:-40px;}
  }
  .search-box{width:calc(100% - 184px);margin-bottom:60px;}
  @include media-breakpoint-down(md){
    .commu-title{
      column-gap:6px;
      h2{width:calc(100% - 67px);padding-bottom:16px;font-size: 16px;line-height: 20.8px;}
      .icon-community{margin-top:-5px;width:61px;height:61px;}
    }
    .search-box{
      margin-bottom:40px;width:100%;
      input{width:100%;}
    }
  }
      
}



.notice-list{
  ul{
    display:flex;flex-direction:column;row-gap:60px;
    li{
      a{
        display:flex;column-gap:80px;
        .img{
          width:312px;height:180px;
          img{width:100%;height:100%;object-fit: cover;}
        }
        .text-box{
          width:calc(100% - 392px);padding:0 40px 48px 0;
          .title{
            display:inline-block;position:relative;margin-bottom:16px;font-size: 24px;font-weight: 700;line-height: 38px;text-overflow: ellipsis;
            overflow: hidden;white-space: nowrap;width: 100%;
          }
        }
        .text{font-size:16px;line-height:25.6px;color:$gray-6;-webkit-box-orient: vertical;display: -webkit-box;white-space: inherit;-webkit-line-clamp: 3;word-break: break-all;}
        &:hover .text-box .title{text-decoration: underline;}
      }
    }
  }
  @include media-breakpoint-down(md){
    ul{
      row-gap:40px;
      li{
        a{flex-direction:column;gap:24px;
          .img{width:100%;}
          .text-box{
            width:100%;padding:0;
            .title{margin-bottom:8px;font-size: 18px;line-height: 28.8px;-webkit-box-orient: vertical;display: -webkit-box;white-space: inherit;-webkit-line-clamp: 2;word-break: break-all;
              &::after{display:none;}
              }
            }
            .text{font-size:14px;line-height:22.4px;}
        }
      }
    }
  }
}



//content-detail 공통
.content-detail-wrap{
  margin-bottom:40px;
  .top-title{padding:24px;font-size: 32px;font-weight: 700;line-height: 51px;border-bottom:1px solid $border-color;}
  .date{display:flex;align-items:center;padding:16px 24px 0 24px;column-gap:8px;color:$gray-6;font-weight:500;}
  .text-box{padding:80px 24px;}
  .file-box{
    padding:0 24px 40px 24px;border-bottom:1px solid $border-color;
    a{
      display:flex;align-items:center;column-gap:8px;color:#999;
    }
  }
  @include media-breakpoint-down(md){
    .top-title{padding:24px 0 16px 0;font-size: 20px;line-height:32px;}
    .date{font-size:14px;margin-bottom:40px;padding:16px 0 0 0;}
    .text-box{padding:40px 0;}
    .file-box{
      padding:0 0 24px 0;
      a{
        font-size:14px;line-height:19.6px;          
      }
    }
  }
}

//blog-list
.blog-list{
  ul{
    row-gap:60px;
    li{
      a{
        flex-direction:column;row-gap:16px;
        .img{
          width:100%;height:240px;margin-bottom:16px;
          img{width:100%;height:100%;object-fit: cover;}
        }
        .title{
          font-size: 18px;font-weight: 700;line-height: 28.8px;
        }
        .text{margin-top:8px;font-size:16px;line-height:25.6px;color:$gray-6;}
        &:hover .text-box .title{text-decoration: underline;}
      }
    }
    
  }
  @include media-breakpoint-down(md){
    ul{
      row-gap:40px;
      li{
        a{
          .img{height:180px;}
          .title{
            font-size: 16px;line-height: 25.6px;
          }
          .text{font-size:14px;line-height:22.4px;}
        }
      }
      
    }
    
  }
}

//security-list
.security-list{
  ul{
    row-gap:60px;
    li{
      a{
        flex-direction:column;row-gap:16px;
        .img{
          width:100%;height:360px;margin-bottom:16px;
            img{width:100%;height:100%;object-fit: cover;}
          }
          .title{font-size: 18px;font-weight: 700;line-height: 28.8px;}
          .text{margin-top:8px;font-size:16px;line-height:25.6px;color:$gray-6;}
          &:hover .text-box .title{text-decoration: underline;}
        }
      }
      @include media-breakpoint-down(md){
      row-gap:40px;
      li{
        a{
          row-gap:40px;
          .img{
            height:180px;
            img{width:100%;height:100%;object-fit: cover;}
            }
            .title{font-size: 14px;line-height: 22.4px;}
            .text{font-size:14px;line-height:22.4px;}
          }
        }
    }
  }
}

.icon-community{
  width:170px;height:170px;background-image: url('../images/icon-community.svg');
}
.icon-search-bk{
  width:14px;height:16px;margin-right:11px;background-image: url('../images/icon-search-bk.svg');
}
.icon-file{
  width:20px;height:20px;background-image: url('../images/icon-file.svg');
}


