.about-section{
  padding: 160px 0;
  @include media-breakpoint-down(lg){
    padding:60px 0;
  }
  .box{
    .title{
      font-size: 32px; font-weight: 700; line-height: 160%;
    }
    .desc{
      font-size: 18px; font-weight: 400; line-height: 160%; color: #666; margin-top: 40px;
    }
    @include media-breakpoint-down(lg){
      .title{ font-size: 24px; }
      .desc{ font-size: 16px; }
    }
  }
  .sign{
    display: flex; gap: 16px; margin-top: 80px; justify-content: end;
    .position{font-size: 18px; font-weight: 700; line-height: 160%; color: #333;}
    .name{color: #333;font-size: 24px;line-height: 160%;}
    @include media-breakpoint-down(lg){
     justify-content: start; gap: 37px; margin-top: 40px;
     .position{font-size: 16px;}
     .name{font-size: 18px; margin-top: 4px;}
    }
  }

  // vision
  .vision-list{
    display: flex; justify-content: space-between; gap: 20px; margin-top: 115px;
    @include media-breakpoint-down(lg){
      flex-flow: wrap; margin-top: 60px;
    }
    .vision-content{
      width: 25%; display: flex; flex-direction: column; align-items: center; padding: 0 40px;
      .title{
        font-size: 24px; font-weight: 700; line-height: 160%; margin-top: 24px;
      }
      .desc{
        font-size: 18px; line-height: 160%; margin-top: 4px; text-align: center;
      }
      @include media-breakpoint-down(lg){
        width: calc(50% - 10px); padding: 0 10px;
        .img{
          width: 70%;
        }
        .title{font-size: 16px;margin-top: 12px; }
        .desc{ font-size: 14px;  }
      }
    }
  }
  // ci
  .ci{
    display: flex; justify-content: space-between;
    @include media-breakpoint-down(lg){
     flex-direction: column;
     .ci-logo{
      width: 70%; margin: 0 auto 40px;
     }
    }
    .introduce{
      width: 45%;
      @include media-breakpoint-down(lg){
        width: 100%;
      }
      .content{
        text-align: right;
        .title{font-size: 18px; font-weight: 700; line-height: 160%; margin-bottom: 16px;}
        .desc{font-size: 18px; line-height: 160%; color: #666; margin-bottom: 40px;}
        @include media-breakpoint-down(lg){
          text-align: center;
        .title{margin-bottom: 16px;}
        .desc{ font-size: 16px; margin-bottom: 40px;}
        }
      }
      .color-list{
        display: flex; gap: 24px; 
        @include media-breakpoint-down(lg){
          flex-flow: wrap; justify-content: center;
        }
        .color{
          width: 20%;
          @include media-breakpoint-down(lg){
            width: calc(33.3% - 16px);
          }
          .bg{
            width: 100%; height: 60px; margin-bottom: 7px;
            &.bg-1{ background-color: #FEDD26;}
            &.bg-2{ background-color: #001A7B;}
            &.bg-3{ background-color: #19B9D8;}
            &.bg-4{ background-color: #4B4C50;}
            &.bg-5{ background-color: #838585;}
          }
          p{ color: #666; font-weight: 700; line-height: 120%; font-size: 14px;}
        }
      }
    }
  }
}
.foot-banner{
  width: 100%; height: 900px; background-image: url('../images/foot-bg.png'); background-size: cover; background-position: center;  background-repeat: no-repeat;
  display: flex; align-items: center; flex-direction: column; justify-content: center;
  .title{font-size: 40px; font-weight: 700; line-height: 130%; color: #fff; text-align: center;}
  .desc{ font-size: 18px; line-height: 160%; color: #fff; margin-top: 40px;  text-align: center;}
  @include media-breakpoint-down(lg){
    height: 400px;
    .title{font-size: 24px;}
    .desc{ font-size: 16px;margin-top: 24px;}
  }
}


// employ
.employ-section{
  padding:160px 0 0;
  &.bg-gray{padding:160px 0;background-color:$bg-color;}
  .top-title{
    display:flex;flex-direction:column;align-items:center;margin-bottom:80px;
    em{display:flex;column-gap:10px;margin-bottom:24px;font-size: 14px;font-weight: 700;line-height: 11px;letter-spacing: 1.2px;text-transform: uppercase;}
    strong{font-size: 32px;font-weight: 700;line-height: 130%;}
  }
  @include media-breakpoint-down(lg){
    padding:80px 0 0;
    &.bg-gray{padding:80px 0;}
    .top-title{
      margin-bottom:40px;
      em{margin-bottom:16px;font-size: 12px;}
      strong{font-size: 20px;line-height: 130%;}
    }
  }
  .place-swiper-box{
    position:relative;
    .place-swiper{
      width:1640px;overflow:hidden;
      .swiper-slide{}
      
    }
    .swiper-pagination{position: relative;bottom: 0;left: 50%;transform: translateX(-50%);margin:65px 0 0;display: flex;align-items: center;max-width:864px;height:2px;background: rgba(0, 0, 0, 0.10);}
    .swiper-pagination-bullet{margin:0;background-color:transparent;width: 108px;height: 8px;margin:0;border-radius: 8px;}
    .swiper-pagination-bullet-active{background-color: $dark;}
    @include media-breakpoint-down(md){
      .swiper-pagination{margin:19px 0 0;}
      .swiper-pagination-bullet{width:70px;height: 4px;border-radius: 4px;}
    }
  }
 

    .item{
      padding:32px 24px;border-radius:24px;background-color: $bg-color;margin-bottom: 20px;
      .img{margin-bottom:8px;text-align: right;}
      .text-box{
        display:flex;flex-direction:column;row-gap:8px;
        strong{font-size: 20px; display:inline-block;color:#000; }
        .text{
          margin-top:4px;
          p{
            color:$gray-6;
          }
        }
      }
      @include media-breakpoint-down(md){
        .item{
          padding:12px 24px;border-radius:18.268px;width:242px !important;height:320px;flex: 0 0 auto;
          .img{
            img{width: 90px;height: 90px;}
          }
          .text-box{
            strong{font-size: 14px;}
            .text{
              margin-top:4px;
              p{
                font-size: 12px;
              }
            }
          } 
        }
      }
    }
  }
  
  .accordion{
    margin-bottom:160px;
    @include media-breakpoint-down(lg){
      margin-bottom:80px;
    }
  }
  .employ-company{
    display:flex;column-gap:24px;
    li{
      width:calc((100% - 48px) / 3);
      a{
        display:flex;align-items:center;flex-direction: column;align-items: center;row-gap: 6px;justify-content:center;height:180px;background:$white;border:1px solid $border-color;border-radius:16px;
        p{
          display: flex;align-items: center;column-gap: 6px;
          strong{font-weight:700;font-size:24px;}
        }
        
      }
    }
    @include media-breakpoint-down(lg){
      flex-direction:column;row-gap:8px;
      li{
        width:100%;
        a{
          row-gap: 5.5px;height:100px;
          img{max-width:154px;}
          p{
            font-size:14px;column-gap: 8px;
            strong{font-size:18px;}
            .icon-emoji{width:30px;height:30px;}
          }
          
        }
      }
    }
  }
  .icon-employ-point{width:12px;height:12px;background:url("../images/icon-employ-point.png") ;}
  .icon-q{width:18px;height:19px;margin:4px 12px 0 0;background:url("../images/icon-q.svg");}
  .icon-emoji{width:40px;height:40px;background:url("../images/icon-emoji.svg");background-size: 100%;}



.history-wrap{
  position: relative; height: 700px;
  @include media-breakpoint-down(md){
    height: 500px;
  }
  .years{
    position: sticky; top: 0; flex-shrink: 0;
    @include media-breakpoint-down(xl){
      position: static; margin-bottom: 100px;
    }
    @include media-breakpoint-down(md){
      margin-bottom: 50px;
    }
    .company{
      display: flex; align-items: center; gap: 10px; font-size: 14px; font-weight: 700; letter-spacing: 1.2px; margin-bottom: 32px;
      @include media-breakpoint-down(xl){
        margin-bottom: 16px;
      }
    }
    .desc{
      font-size: 40px; line-height: 130%; margin-bottom: 40px; 
      @include media-breakpoint-down(md){
        font-size: 24px; margin-bottom: 16px; 
      }
    }
    
  }
  .history-list{
    width: 57%; height: 100%; overflow: auto; position: absolute; top: 0; right: 0; 
    @include media-breakpoint-down(xl){
      width: 100%; position: static;
    }
    /* 아래의 모든 코드는 영역::코드로 사용 */
    &::-webkit-scrollbar {
      width: 6px;  /* 스크롤바의 너비 */
      @include media-breakpoint-down(md){
        width: 4px;
      }
    }

    &::-webkit-scrollbar-thumb {
      height: 15%; /* 스크롤바의 길이 */
      background: #222; /* 스크롤바의 색상 */
      border-radius: 24px;
    }
    &::-webkit-scrollbar-track {
      background: rgba(0, 0, 0, 0.10);  /*스크롤바 뒷 배경 색상*/
    }
    .history-content{
      display: flex; gap: 23px; padding-top: 40px; padding-right: 25px;
      @include media-breakpoint-down(xl){
        gap: 40px;
      }
      @include media-breakpoint-down(md){
        gap: 23px; padding-top: 25px; padding-right: 15px;
      }
      .years-txt{
        font-size: 32px; font-weight: 700; color: $primary; flex-shrink: 0; line-height: 1;
        @include media-breakpoint-down(md){
          font-size: 20px;
        }
      }
      &:first-child{
        padding-top: 0;
      }
      ul{
        display: flex; flex-direction: column; gap: 32px;  border-bottom: 1px solid #eee; padding-bottom: 40px;
        @include media-breakpoint-down(md){
          padding-bottom: 25px; gap: 20px;
        }
        li{
          display: flex; 
          .key{
            font-size: 18px; font-weight: 700; width: 80px; flex-shrink: 0;
            @include media-breakpoint-down(md){
              width: 65px; font-size: 16px;
            }
          }
          .value{
            font-size: 18px; color: #666;
            @include media-breakpoint-down(md){
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}

// location
.location-section{
  .box{
    margin-bottom: 80px;
    @include media-breakpoint-down(lg){
      margin-bottom: 40px;
    }
    .title{
      font-size: 32px; font-weight: 700; line-height: 160%;
    }
    .desc{
      font-size: 18px; font-weight: 400; line-height: 160%; color: #666; margin-top: 32px;
    }
    ul{
      padding-left: 20px; margin-top: 32px; display: flex; flex-direction: column; gap: 8px;
      @include media-breakpoint-down(lg){
        margin-top: 16px;
      }
      li{
        list-style: disc; font-size: 18px; color: #666; line-height: 160%;
        @include media-breakpoint-down(lg){
          font-size: 16px;
        }
        .second-line{
          display: inline-block; width: 24px; height: 24px; border-radius: 50%; background-color: #00C62C; color: #fff; font-weight: 700;     display: inline-flex; align-items: center; justify-content: center;    line-height: 1;
          @include media-breakpoint-down(lg){
            width: 18px; height: 18px; font-size: 12px;
          }
        }
        .sixth-line{
          display: inline-block; width: 24px; height: 24px; border-radius: 50%; background-color: #BA814D; color: #fff; font-weight: 700;
          display: inline-flex; align-items: center; justify-content: center;    line-height: 1;
          @include media-breakpoint-down(lg){
            width: 18px; height: 18px; font-size: 12px;
          }
        }
      }
    }
    @include media-breakpoint-down(lg){
      margin-bottom: 40px;
      .title{ font-size: 24px; }
      .desc{ font-size: 16px; }
    }
    #map{
      width: 100%; height: 600px; background-color: #D9D9D9;
      @include media-breakpoint-down(lg){
        height: 300px;
      }
    }
  }
}

.privacy-wrap{
  .title{
    font-size: 40px; font-weight: 700; padding-bottom: 40px;
    @include media-breakpoint-down(lg){
      font-size: 24px; padding-bottom: 30px;
    }
  }
  .desc{
    font-size: 18px; line-height: 1.67; color: #666;
    @include media-breakpoint-down(lg){
      font-size: 15px;
    }
  }
  .box{
    margin-top: 40px;
  }
  .sub-title{
    font-size: 20px; font-weight: 700; line-height: 1.5; color: #000;
  }
  .sub-desc{
    font-size: 18px; line-height: 1.67; color: #666; margin-top: 15px;
  }
  .depth1{
    margin-top: 5px;
    li{ 
      font-size: 16px; line-height: 1.88; color: #666;
    }
  }
  .depth2{
    margin-top: 20px; padding-left: 15px;
    .depth-title{
      font-weight: 700; 
    }
    .depth-desc{
      color: #666;
    }
  }
}