// default가 md
.btn{
    width: auto;
    display: inline-block;
    font-weight: 600;
    color: #000;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    height:44px;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0 24px;
    border-radius: 8px;
    font-size: 15px;
    line-height: 160%;
    cursor: pointer;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
   
 
    &.btn-primary{
      background-color: $primary !important;
      color: $white !important;
      &:hover{
        background-color: rgba(0, 0, 0, 0.06) !important;
      }
      &:disabled{
        opacity: .3;
      }
    }
    &.btn-outline-primary{
      background-color: $white !important;
      border: 1px solid $primary !important;
      color: $primary !important;
      &:hover{
        background-color: rgba(0, 0, 0, 0.06) !important;
      }
      &:disabled{
        opacity: .3;
      }
    }
    &.btn-secondary{
      background-color: $secondary !important;
      color: $primary !important;
    }
    &.btn-dark{
      background-color: $dark !important;
      color: $white !important;
    }
    &.btn-outline-dark{
      background-color: $white !important;
      border: 1px solid $dark !important;
      color: $dark !important;
    }
    &.btn-gray{
      background-color: $gray !important;
      color: $white !important;
    }
    &.btn-outline-gray{
      background-color: $white !important;
      border: 1px solid $gray !important;
      color: $gray !important;
    }
    &.btn-gray-dark{
      background-color: #999 !important;
      color: $white !important;
    }
    &.btn-outline-white{
      background-color: transparent !important;
      border: 1px solid $white !important;
      color: $white !important;
    }
    &.btn-outline-danger-dark {
      border: 1px solid $danger-dark !important;
      color: $danger-dark !important;
      border-radius: 24px;
    }
    &.btn-outline-black {
      border: 1px solid #222 !important;
      color: #000 !important;
      border-radius: 24px;
    }
  
}